import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetUserRoleService {


  private currUserRole: string = "";

  setUserRole(role: string): void
  {
    this.currUserRole = role;
  }

  getUserRole(): string
  {
    return this.currUserRole;
  }
}
